body{background:#f5f5f5;font-size:1.06rem}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.side-text{
font-size: 14px;
    line-height: 1.3;
    margin: 0px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.offer_image {
  max-width: 175px;
}
.rating_num {
	display:flex;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1160px) {
    .first-box-card, .third-box-card {
        margin-top: 45px !important;
    }
    .first-box-card, .third-box-card {
        padding-top: 5px;
    }
    .second-card-header {
        padding-top: 45px;
        margin-bottom: 20px;
    }	
}
	 .top-card-row {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		transition: all 0.3s ease;
		overflow-x: auto;
		overflow-y: hidden;
		margin: auto;	 
	 }

.offer-discount-mobile {display:none!important;}

@media screen and (max-width: 1159px) {
   .top-text { position:relative;max-width: 100%;}
   .second-card-header {
        padding-top: 0px;
    } 
 	 #hero-bg{
		position: relative;
		opacity: 1;
		background-position: 95% 14% !important;
        display: flex;
        flex-direction: column;
        justify-content: end;		
	 } 
    #overlay {
        position: absolute;
        display: none;
        width: 100%;
        min-height: 200px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(0 0 0 / 45%);
        z-index: 2;
        cursor: pointer;
    }	 
   .MuiTypography-first_score_number {font-size:2rem !important;} 

	.top-text {
		margin: 0;
		line-height: 1.25rem;
		font-size: 0.9375rem;
		margin-left: 0;
		margin-bottom: 0.625rem;
		text-align: center;
		padding: 10px 5px 5px;
		width: 100%;
		max-width: 300px;
		position:relative;
		
	}	


}

@media screen and (min-width: 1160px) {
    .top-scroll-bg {
        margin-top: -60px !important;
    }
	.top-text {
	   position:absolute;
		width: 100%;
		max-width: 300px;
	}
}
@media screen and (min-width: 768px) {
.toggleReviews {display:none!important;}	
}

@media screen and (max-width: 767px) {
	
	.second-box-card{
		order: 1;
	}
	.first-box-card{
		order: 2;
	}
	.third-box-card{
		order: 3;
	}
   .top-text { position:relative;max-width: 100%;}
   .second-card-header {
        padding-top: 25px;
    } 
   .MuiTypography-first_score_number {font-size:2rem !important;} 
	 .items-row{flex-direction: column !important; }
	 .inner-items-row{flex-direction: column !important;}	
	 .items-third-column {padding-top:10px}

     .hide-on-mobile {
        display: none!important;
    }
	.items-second-column{
		order: 3;
		padding-left: 30px;
		padding-top: 10px;
	}	
	.reviews-box {
		display:flex;
		justify-content:center;
		width:100%;
		}
	.items-second-column h3{display:none}	
	.rating_num{flex-basis:unset!important;}
    .hide-on-mobile {
        display: none!important;
    }
	.offer-discount-mobile {display:block!important;}
	.offer-discount-dsk	 {display:none!important;}
	.toggleReviews {display:block;}
	.verified_box {
		width: 100%;
		display: flex!important;
		justify-content: center;
		align-items: center;
		padding-top: 5px;
	}
	.toggleReviews {
		text-align: center;
		color: #111;
		line-height: 35px !important;
		margin-bottom: 0;
		cursor: pointer;
		background: #d9d9d9;
		border-radius: 0 0 3px 3px;
		width: 100%;
		left: 0%;
		position: relative;
		border-radius: 0 0 10px 10px;
		margin:-5px auto 0!important;
		max-width:320px;
	}	
}
.score-title {display:flex;align-items:center;}
.score-title img {width:18px;margin-right:10px;}
.sidebar-section p{margin:0px;font-size: 0.9rem;line-height: 1.1em;}
p.score-text{margin: 0 0px 0 25px;}
.score-title p{
    font-size: 0.95rem;
    font-weight: 700;
    line-height: 1em;
	margin:5px 0px;
}
.sidebar-section h3 {
    font-size: 0.9rem;
    margin: 0.5rem 0rem;
	line-height: 1em;
}
.sidebar-section h2 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
	margin-top: 0px;
    width: 100%;
    color: #000;
	line-height: 1em;
}
.score-heading{
    display: flex;
    align-items: center;
}
.score-heading img {
    width: 40px;
    height: 40px;
}
#features{
  max-width: 1100px;
}
.sectionSidebar{
    padding-left: 20px!important;
    padding-top: 10px!important;
}
.sectionMain{
	padding-top: 10px!important;
}
.sectionMain .middle-content h2{
    margin: 0.5rem 0px;
    font-size: 1.6rem;
    font-weight: bold;
}
.middle-content p {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
@media screen and (min-width: 560px) and (max-width: 1159px) {
    .b-star-ratings {
        display: none;
    }
    .top-card {
        min-width: 314px;
    }	
}
@media screen and (max-width: 559px){
    .top-card {
        min-width: 280px;
    }
  .popup-container {max-width:370px !important;} 
}
@media (max-width: 1000px) {
    .sidebar {
        display: none;
    }
    .sectionMain {
        flex-basis: 100%!important;
		max-width: 100%!important;
    }	
}

@media screen and (max-width: 540px){
.footer-section {display:block!important;text-align:center}	
.footer-menu {
    display: block !important;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -10px;
  }
.view_plans_link{max-width:320px;}  
}
@media (max-width: 739px) {
    .avg-score {
        font-size: 32px!important;
		margin-bottom:10px !important;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
	.first-offer.offer-discount-mobile{
		margin-top:1.8em!important;
	}
	.view_plans_link{
		margin-top: 0.5em!important;
		margin-left: 2.4em!important;
	}
	.view_plans_link span{
	    width: 100%;
        max-width: 558px;
	}
	.items-second-column li{margin-top:-4px!important; } 
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color:#222;
}

.popup-content h2 {
  margin-top: 0;
  color:#222;
}

.popup-content p {
  margin-bottom: 20px;
  color:#222;
}
#hero {padding-bottom:10px;}
.second-sidebar p {margin:5px 0px}
.modal-content {background: none;} 
.modal-content {
    background: none;
    height: 100vh;
    border: 0px;
}
.MuiAppBar-positionFixed.mui-fixed{z-index:1000;}
.items-row a:hover{color:inherit}

.wrapper_btn_view::after{
	margin-left:6px;
}

@media only screen and (min-width: 768px) and (max-width: 834px) and (orientation: portrait) {
  .items-first-column, .items-third-column {flex-basis: 70% !important;}
}
@media (min-width: 1000px) {
	.items-second-column{
		margin-left: 10px !important;
	}
}
.popup-container h3 {
    font-weight: bolder;
    font-size: 1.55em;
}
.popup-container h2 {
    font-size: 3em;
    margin: 15px 10px;
    line-height: 1;
    font-weight: bold;
    color: #e16c02;
    text-transform: uppercase;
    letter-spacing: -1px;
}

@media screen and (max-width: 540px){
	.popup-container h2 {
		margin: 10px 10px;
	 }	
}

@media (min-width: 1536px) {
.items-second-column{flex-basis: 47% !important;max-width: 47%!important;}
.items-third-column {flex-basis: 28% !important;max-width: 28%!important;}
}

.modal.show .modal-dialog {
    max-width: 100%;
}
#hero-bg{position:relative}
#hero-bg:before{
content:'';
width:100%;
height:100%;
position:absolute;
background: linear-gradient(to right, rgba(0, 0, 0, 0.52) 30%, rgba(0, 0, 0, 0) 70%);
top: 0px;
left: 0px;	
}

@media screen and (max-width: 1159px) {
#hero-bg:before{
content:'';
width:100%;
height:100%;
position:absolute;
background: linear-gradient(to right, rgba(0, 0, 0, 0.52) 20%, rgba(0, 0, 0, 0) 100%);
top: 0px;
left: 0px;	
}	
}

button.close {
    position: absolute;
    z-index: 99;
    background: transparent;
    border: none;
    font-size: 2rem;
    right: 0px;
    top: 0px;
    border-radius: 50%;
    padding: 0 12px 0px;
	line-height: 2.2rem;
}
.modal {
    padding-left: 0px !important;
}

@media only screen and (min-width: 768px) and (max-width: 1380px) and (orientation: landscape) {
  .verifybox{
	  text-align:center !important;
      padding-right: 5px;
  }
}

.popup-container h2.text-special {
    font-size: 2.1em;
	line-height:1.1;
}